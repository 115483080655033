import { AnimatedTextBlock } from "@components/atoms/AnimatedTextBlock"
import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper"
import { Pill } from "@components/atoms/Pill"
import { defineBlock, EditableText, InnerBlocks } from "eddev/blocks"
import Marquee from "react-fast-marquee"

export const meta: BlockMeta = {
  title: "Home Social",
}

export default defineBlock("home/social", (props) => {
  // hide for now
  return null

  return (
    <BlockWrapper toolbarState="course-progress">
      <BlockItem className="bg-beige" innerClassName="mx-0 md:mx-0">
        <div className="mx-5">
          <div className="flex flex-col gap-5 md:flex-row-reverse md:justify-between">
            <div className="shrink-0">
              <Pill className="bg-beige-dark shrink-0">
                <EditableText
                  as="span"
                  className="shrink-0"
                  store="social-hash"
                  inlineToolbar={false}
                  defaultValue="#thebestplaceintheworldtohaveherpes"
                  placeholder="#thebestplaceintheworldtohaveherpes"
                />
              </Pill>
            </div>
            <div className="md:w-1/2">
              <AnimatedTextBlock textKey="title" textClassName="type-title-l" triggerStart="top bottom-=15%" triggerEnd="bottom top" placeholder="Spread it online." />
              <AnimatedTextBlock textKey="body" className="mt-6" textClassName="type-body-l" triggerStart="top bottom-=15%" triggerEnd="bottom top" placeholder="The best way to fight stigma is by sharing your story. Tag us on social for the greatest of honours: being featured in this carousel." />
            </div>
          </div>
        </div>
        <Marquee autoFill={!env.admin} pauseOnHover play={!env.admin} speed={30}>
          <div className="mt-9 flex items-start justify-start flex-nowrap gap-6 admin-innerblocks:w-full admin-innerblocks-child:flex">
            <InnerBlocks
              template={[
                ["home/social-item", null],
              ]}
              allowedBlocks={["home/social-item"]}
              orientation="horizontal"
            />
          </div>
        </Marquee>
      </BlockItem>
    </BlockWrapper>
  )
})